import React from 'react';

import SubLayout from '../components/SubLayout';
import AccessInfoArea from '../components/AccessInfoArea';

const Access = () => (
  <SubLayout topTitle="アクセス">
    <div
      css={`
        margin-bottom: 50px;
      `}
    >
      <AccessInfoArea />
    </div>
  </SubLayout>
);

export default Access;
